'use client'

import {usePathname, useSearchParams} from 'next/navigation'
import {useEffect} from 'react'
import Script from 'next/script'

declare global {
  interface Window {
    dataLayer: any[]
  }
}

export default function GoogleTagManager() {
  const pathname = usePathname()
  const searchParams = useSearchParams()

  useEffect(() => {
    if (pathname) {
      window.dataLayer = window.dataLayer || []

      window.dataLayer.push({
        event: 'pageview',
        page: pathname,
        search: searchParams?.toString(),
      })
    }
  }, [pathname, searchParams])

  return (
    <>
      <Script
        id='gtm-script'
        strategy='afterInteractive'
        dangerouslySetInnerHTML={{
          __html: `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer', '${process.env.NEXT_PUBLIC_GTM_ID}');
          `,
        }}
      />
      <noscript>
        <iframe
          src={`https://www.googletagmanager.com/ns.html?id= '${process.env.NEXT_PUBLIC_GTM_ID}'`}
          height='0'
          width='0'
          style={{display: 'none', visibility: 'hidden'}}
        />
      </noscript>
    </>
  )
}
